export const galleryField = [
  {
    label: '定制产品名称',
    prop: 'name',
    name: 'name',
    colNum: 4,
    placeholder: '输入定制产品名称'
  },
  // {
  //   label: '款式',
  //   prop: 'styleName',
  //   name: 'styleName',
  //   colNum: 4,
  //   placeholder: '输入款式'
  // },
  {
    label: '产品分类',
    prop: 'categoryId',
    minWidth: '100',
    placeholder: '请选择产品分类',
    colNum: 4,
    slotName: 'protoTypeCategorySlot'
  },
  {
    label: '原型名称',
    prop: 'prototypeName',
    name: 'prototypeName',
    placeholder: '输入原型名称',
    colNum: 4
  },
  {
    label: '分销商',
    prop: 'mainUserName',
    name: 'mainUserName',
    placeholder: '输入分销商',
    colNum: 4
  },

  // {
  //   label: '内部SKU',
  //   prop: 'sku',
  //   name: 'sku',
  //   placeholder: '输入内部SKU',
  //   colNum: 4
  // },
  {
    label: '定制时间',
    prop: 'createTime',
    name: 'createTime',
    colNum: 8,
    slotName: 'designedTimeSlot',
    placeholder: '请选择时间'
  },
  {
    placeholder: '输入自定义SKU',
    slotName: 'multiplySearchSlot',
    colNum: 6
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]
