<template>
  <section>
    <BasePicListPage
      :needTopPaginationBar="false"
      :cols="cols"
      :designedStyle="true"
      :formField="formField"
      :status="1"
      resetUrl="/externaladmin/productService/productPrototypeCollection/productCustomList"
      :isSearch="isSearch"
      @selectionChange="selectionChange"
    >
      <template v-slot:batchOperation="{ sup_this }">
        <BatchOperation class="batch-operation-wrapper">
          <div>
            <el-button
              v-p="['externaladmin:user:designedProductManage:batchDownload']"
              size="mini"
              type="primary"
              @click="batchDownloadShowImage"
            >批量下载效果图</el-button
            >
            <ExportOriginPic v-p="['externaladmin:user:designedProductManage:exportOriginPic']" :sup_this="sup_this" />
          </div>
        </BatchOperation>
      </template>

      <template v-slot="{ sup_this }">
        <el-table-column fixed="right" align="center" label="操作" width="300px">
          <template slot-scope="{ row }">
            <span v-p="['externaladmin:user:designedProductManage:viewDetails']" class="watch" @click="onWatch(row)"
              >查看详情</span
            >
            <span class="line"></span>
            <span
              class="watch"
              v-p="['externaladmin:user:designedProductManage:update']"
              @click="onUpdate(row, sup_this)"
              >更新效果图</span
            >
            <span class="line"></span>
            <span class="watch" v-p="['externaladmin:user:designedProductManage:download']" @click="onDownload(row)"
              >下载效果图</span
            >
          </template>
        </el-table-column>
      </template>
    </BasePicListPage>
    <watchDialog v-if="show" :visible.sync="show" :product="designedProduct" />
  </section>
</template>

<script>
import BasePicListPage from '../picture/basePicListPage'
import watchDialog from '../designedProductManage/module/watchDialog'
import ExportOriginPic from './module/exportOriginPic'
import { downloadByZip } from '@/utils/download'
import { testAlgoShowImg } from '@/api/product/knifeProductApi'
import { galleryField as formField } from './field'
import { detail } from '@/api/product/designed'
import { imageListCols as cols } from '../picture/basePicListPage/module/designedDialog/cols'
import { createRandomNum } from '@/utils'

export default {
  components: {
    BasePicListPage,
    watchDialog,
    ExportOriginPic
  },

  data() {
    return {
      show: false,
      isSearch: this.checkPermission(['externaladmin:user:designedProductManage:search']),
      designedProduct: {},
      selectionData: [],
      cols,
      formField
    }
  },

  methods: {
    downloadByZip,
    async onWatch(data) {
      const { id } = data
      await detail({ id }).then(({ detail }) => {
        this.designedProduct = detail
      })
      this.show = true
    },
    selectionChange(data) {
      this.selectionData = data
    },
    batchDownloadShowImage() {
      if (this.selectionData.length == 0) {
        this.$message.warning('请先选择产品后再批量下载效果图')
        return
      }
      let paths = []
      this.selectionData.map(({ customProductList }) => {
        customProductList.map(({ customShowImageList, styleName }) => {
          customShowImageList.map((customShowImage, index) => {
            const url = customShowImage.showImagePath
            const curIndex = index + 1
            const name = styleName + '-' + createRandomNum() + '-' + curIndex
            if (url) {
              paths.push({
                url,
                name,
                type: 'upload',
                status: '0',
                thumbnailPath: '',
                loaded: 0,
                total: 0.000001
              })
            }
          })
        })
      })
      this.downloadByZip(paths)
    },
    onUpdate(data, sup_this) {
      const { customProductList } = data
      customProductList.map(async ({ id }) => {
        let res = await awaitResolve(
          testAlgoShowImg({
            customProductId: id
          })
        )
        if (res) {
          sup_this.init()
          this.$message.success('生成成功')
        }
      })
    },
    onDownload(data) {
      let paths = []
      data.customProductList.map(({ customShowImageList, styleName }) => {
        customShowImageList.map(({ showImagePath: url }, index) => {
          const curIndex = index + 1
          const name = styleName + '-' + createRandomNum() + '-' + curIndex
          if (url) {
            paths.push({
              url,
              name,
              type: 'upload',
              status: '0',
              thumbnailPath: '',
              loaded: 0,
              total: 0.000001
            })
          }
        })
      })
      this.downloadByZip(paths)
    }
  }
}
</script>

<style lang="scss" scoped>
.watch {
  color: #3841db;
  font-size: 14px;
  cursor: pointer;
}
</style>
